/*
 * Copyright 2010-2018 JetBrains s.r.o. Use of this source code is governed by the Apache 2.0 license 
 * that can be found in the license/LICENSE.txt file.
 */

@file:kotlin.jvm.JvmMultifileClass
@file:kotlin.jvm.JvmName("ArraysKt")

package kotlin.collections

//
// NOTE: THIS FILE IS AUTO-GENERATED by the GenerateStandardLib.kt
// See: https://github.com/JetBrains/kotlin/tree/master/libraries/stdlib
//

import kotlin.js.*
import primitiveArrayConcat
import withType
import kotlin.*
import kotlin.text.*
import kotlin.comparisons.*

/**
 * Returns a [List] that wraps the original array.
 */
public actual fun <T> Array<out T>.asList(): List<T> {
    return ArrayList<T>(this.unsafeCast<Array<Any?>>())
}

/**
 * Returns a [List] that wraps the original array.
 */
@kotlin.internal.InlineOnly
public actual inline fun ByteArray.asList(): List<Byte> {
    return this.unsafeCast<Array<Byte>>().asList()
}

/**
 * Returns a [List] that wraps the original array.
 */
@kotlin.internal.InlineOnly
public actual inline fun ShortArray.asList(): List<Short> {
    return this.unsafeCast<Array<Short>>().asList()
}

/**
 * Returns a [List] that wraps the original array.
 */
@kotlin.internal.InlineOnly
public actual inline fun IntArray.asList(): List<Int> {
    return this.unsafeCast<Array<Int>>().asList()
}

/**
 * Returns a [List] that wraps the original array.
 */
@kotlin.internal.InlineOnly
public actual inline fun LongArray.asList(): List<Long> {
    return this.unsafeCast<Array<Long>>().asList()
}

/**
 * Returns a [List] that wraps the original array.
 */
@kotlin.internal.InlineOnly
public actual inline fun FloatArray.asList(): List<Float> {
    return this.unsafeCast<Array<Float>>().asList()
}

/**
 * Returns a [List] that wraps the original array.
 */
@kotlin.internal.InlineOnly
public actual inline fun DoubleArray.asList(): List<Double> {
    return this.unsafeCast<Array<Double>>().asList()
}

/**
 * Returns a [List] that wraps the original array.
 */
@kotlin.internal.InlineOnly
public actual inline fun BooleanArray.asList(): List<Boolean> {
    return this.unsafeCast<Array<Boolean>>().asList()
}

/**
 * Returns a [List] that wraps the original array.
 */
public actual fun CharArray.asList(): List<Char> {
    return object : AbstractList<Char>(), RandomAccess {
        override val size: Int get() = this@asList.size
        override fun isEmpty(): Boolean = this@asList.isEmpty()
        override fun contains(element: Char): Boolean = this@asList.contains(element)
        override fun get(index: Int): Char = this@asList[index]
        override fun indexOf(element: Char): Int = this@asList.indexOf(element)
        override fun lastIndexOf(element: Char): Int = this@asList.lastIndexOf(element)
    }
}

/**
 * Returns `true` if the two specified arrays are *deeply* equal to one another,
 * i.e. contain the same number of the same elements in the same order.
 * 
 * If two corresponding elements are nested arrays, they are also compared deeply.
 * If any of arrays contains itself on any nesting level the behavior is undefined.
 */
@SinceKotlin("1.1")
@library("arrayDeepEquals")
public actual infix fun <T> Array<out T>.contentDeepEquals(other: Array<out T>): Boolean {
    definedExternally
}

/**
 * Returns a hash code based on the contents of this array as if it is [List].
 * Nested arrays are treated as lists too.
 * 
 * If any of arrays contains itself on any nesting level the behavior is undefined.
 */
@SinceKotlin("1.1")
@library("arrayDeepHashCode")
public actual fun <T> Array<out T>.contentDeepHashCode(): Int {
    definedExternally
}

/**
 * Returns a string representation of the contents of this array as if it is a [List].
 * Nested arrays are treated as lists too.
 * 
 * If any of arrays contains itself on any nesting level that reference
 * is rendered as `"[...]"` to prevent recursion.
 * 
 * @sample samples.collections.Arrays.ContentOperations.contentDeepToString
 */
@SinceKotlin("1.1")
@library("arrayDeepToString")
public actual fun <T> Array<out T>.contentDeepToString(): String {
    definedExternally
}

/**
 * Returns `true` if the two specified arrays are *structurally* equal to one another,
 * i.e. contain the same number of the same elements in the same order.
 */
@SinceKotlin("1.1")
@library("arrayEquals")
public actual infix fun <T> Array<out T>.contentEquals(other: Array<out T>): Boolean {
    definedExternally
}

/**
 * Returns `true` if the two specified arrays are *structurally* equal to one another,
 * i.e. contain the same number of the same elements in the same order.
 */
@SinceKotlin("1.1")
@library("arrayEquals")
public actual infix fun ByteArray.contentEquals(other: ByteArray): Boolean {
    definedExternally
}

/**
 * Returns `true` if the two specified arrays are *structurally* equal to one another,
 * i.e. contain the same number of the same elements in the same order.
 */
@SinceKotlin("1.1")
@library("arrayEquals")
public actual infix fun ShortArray.contentEquals(other: ShortArray): Boolean {
    definedExternally
}

/**
 * Returns `true` if the two specified arrays are *structurally* equal to one another,
 * i.e. contain the same number of the same elements in the same order.
 */
@SinceKotlin("1.1")
@library("arrayEquals")
public actual infix fun IntArray.contentEquals(other: IntArray): Boolean {
    definedExternally
}

/**
 * Returns `true` if the two specified arrays are *structurally* equal to one another,
 * i.e. contain the same number of the same elements in the same order.
 */
@SinceKotlin("1.1")
@library("arrayEquals")
public actual infix fun LongArray.contentEquals(other: LongArray): Boolean {
    definedExternally
}

/**
 * Returns `true` if the two specified arrays are *structurally* equal to one another,
 * i.e. contain the same number of the same elements in the same order.
 */
@SinceKotlin("1.1")
@library("arrayEquals")
public actual infix fun FloatArray.contentEquals(other: FloatArray): Boolean {
    definedExternally
}

/**
 * Returns `true` if the two specified arrays are *structurally* equal to one another,
 * i.e. contain the same number of the same elements in the same order.
 */
@SinceKotlin("1.1")
@library("arrayEquals")
public actual infix fun DoubleArray.contentEquals(other: DoubleArray): Boolean {
    definedExternally
}

/**
 * Returns `true` if the two specified arrays are *structurally* equal to one another,
 * i.e. contain the same number of the same elements in the same order.
 */
@SinceKotlin("1.1")
@library("arrayEquals")
public actual infix fun BooleanArray.contentEquals(other: BooleanArray): Boolean {
    definedExternally
}

/**
 * Returns `true` if the two specified arrays are *structurally* equal to one another,
 * i.e. contain the same number of the same elements in the same order.
 */
@SinceKotlin("1.1")
@library("arrayEquals")
public actual infix fun CharArray.contentEquals(other: CharArray): Boolean {
    definedExternally
}

/**
 * Returns a hash code based on the contents of this array as if it is [List].
 */
@SinceKotlin("1.1")
@library("arrayHashCode")
public actual fun <T> Array<out T>.contentHashCode(): Int {
    definedExternally
}

/**
 * Returns a hash code based on the contents of this array as if it is [List].
 */
@SinceKotlin("1.1")
@library("arrayHashCode")
public actual fun ByteArray.contentHashCode(): Int {
    definedExternally
}

/**
 * Returns a hash code based on the contents of this array as if it is [List].
 */
@SinceKotlin("1.1")
@library("arrayHashCode")
public actual fun ShortArray.contentHashCode(): Int {
    definedExternally
}

/**
 * Returns a hash code based on the contents of this array as if it is [List].
 */
@SinceKotlin("1.1")
@library("arrayHashCode")
public actual fun IntArray.contentHashCode(): Int {
    definedExternally
}

/**
 * Returns a hash code based on the contents of this array as if it is [List].
 */
@SinceKotlin("1.1")
@library("arrayHashCode")
public actual fun LongArray.contentHashCode(): Int {
    definedExternally
}

/**
 * Returns a hash code based on the contents of this array as if it is [List].
 */
@SinceKotlin("1.1")
@library("arrayHashCode")
public actual fun FloatArray.contentHashCode(): Int {
    definedExternally
}

/**
 * Returns a hash code based on the contents of this array as if it is [List].
 */
@SinceKotlin("1.1")
@library("arrayHashCode")
public actual fun DoubleArray.contentHashCode(): Int {
    definedExternally
}

/**
 * Returns a hash code based on the contents of this array as if it is [List].
 */
@SinceKotlin("1.1")
@library("arrayHashCode")
public actual fun BooleanArray.contentHashCode(): Int {
    definedExternally
}

/**
 * Returns a hash code based on the contents of this array as if it is [List].
 */
@SinceKotlin("1.1")
@library("arrayHashCode")
public actual fun CharArray.contentHashCode(): Int {
    definedExternally
}

/**
 * Returns a string representation of the contents of the specified array as if it is [List].
 * 
 * @sample samples.collections.Arrays.ContentOperations.contentToString
 */
@SinceKotlin("1.1")
@library("arrayToString")
public actual fun <T> Array<out T>.contentToString(): String {
    definedExternally
}

/**
 * Returns a string representation of the contents of the specified array as if it is [List].
 * 
 * @sample samples.collections.Arrays.ContentOperations.contentToString
 */
@SinceKotlin("1.1")
@library("arrayToString")
public actual fun ByteArray.contentToString(): String {
    definedExternally
}

/**
 * Returns a string representation of the contents of the specified array as if it is [List].
 * 
 * @sample samples.collections.Arrays.ContentOperations.contentToString
 */
@SinceKotlin("1.1")
@library("arrayToString")
public actual fun ShortArray.contentToString(): String {
    definedExternally
}

/**
 * Returns a string representation of the contents of the specified array as if it is [List].
 * 
 * @sample samples.collections.Arrays.ContentOperations.contentToString
 */
@SinceKotlin("1.1")
@library("arrayToString")
public actual fun IntArray.contentToString(): String {
    definedExternally
}

/**
 * Returns a string representation of the contents of the specified array as if it is [List].
 * 
 * @sample samples.collections.Arrays.ContentOperations.contentToString
 */
@SinceKotlin("1.1")
@library("arrayToString")
public actual fun LongArray.contentToString(): String {
    definedExternally
}

/**
 * Returns a string representation of the contents of the specified array as if it is [List].
 * 
 * @sample samples.collections.Arrays.ContentOperations.contentToString
 */
@SinceKotlin("1.1")
@library("arrayToString")
public actual fun FloatArray.contentToString(): String {
    definedExternally
}

/**
 * Returns a string representation of the contents of the specified array as if it is [List].
 * 
 * @sample samples.collections.Arrays.ContentOperations.contentToString
 */
@SinceKotlin("1.1")
@library("arrayToString")
public actual fun DoubleArray.contentToString(): String {
    definedExternally
}

/**
 * Returns a string representation of the contents of the specified array as if it is [List].
 * 
 * @sample samples.collections.Arrays.ContentOperations.contentToString
 */
@SinceKotlin("1.1")
@library("arrayToString")
public actual fun BooleanArray.contentToString(): String {
    definedExternally
}

/**
 * Returns a string representation of the contents of the specified array as if it is [List].
 * 
 * @sample samples.collections.Arrays.ContentOperations.contentToString
 */
@SinceKotlin("1.1")
@library("arrayToString")
public actual fun CharArray.contentToString(): String {
    definedExternally
}

/**
 * Returns new array which is a copy of the original array.
 * 
 * @sample samples.collections.Arrays.CopyOfOperations.copyOf
 */
@Suppress("ACTUAL_WITHOUT_EXPECT", "NOTHING_TO_INLINE")
public actual inline fun <T> Array<out T>.copyOf(): Array<T> {
    return this.asDynamic().slice()
}

/**
 * Returns new array which is a copy of the original array.
 * 
 * @sample samples.collections.Arrays.CopyOfOperations.copyOf
 */
@Suppress("NOTHING_TO_INLINE")
public actual inline fun ByteArray.copyOf(): ByteArray {
    return this.asDynamic().slice()
}

/**
 * Returns new array which is a copy of the original array.
 * 
 * @sample samples.collections.Arrays.CopyOfOperations.copyOf
 */
@Suppress("NOTHING_TO_INLINE")
public actual inline fun ShortArray.copyOf(): ShortArray {
    return this.asDynamic().slice()
}

/**
 * Returns new array which is a copy of the original array.
 * 
 * @sample samples.collections.Arrays.CopyOfOperations.copyOf
 */
@Suppress("NOTHING_TO_INLINE")
public actual inline fun IntArray.copyOf(): IntArray {
    return this.asDynamic().slice()
}

/**
 * Returns new array which is a copy of the original array.
 * 
 * @sample samples.collections.Arrays.CopyOfOperations.copyOf
 */
public actual fun LongArray.copyOf(): LongArray {
    return withType("LongArray", this.asDynamic().slice())
}

/**
 * Returns new array which is a copy of the original array.
 * 
 * @sample samples.collections.Arrays.CopyOfOperations.copyOf
 */
@Suppress("NOTHING_TO_INLINE")
public actual inline fun FloatArray.copyOf(): FloatArray {
    return this.asDynamic().slice()
}

/**
 * Returns new array which is a copy of the original array.
 * 
 * @sample samples.collections.Arrays.CopyOfOperations.copyOf
 */
@Suppress("NOTHING_TO_INLINE")
public actual inline fun DoubleArray.copyOf(): DoubleArray {
    return this.asDynamic().slice()
}

/**
 * Returns new array which is a copy of the original array.
 * 
 * @sample samples.collections.Arrays.CopyOfOperations.copyOf
 */
public actual fun BooleanArray.copyOf(): BooleanArray {
    return withType("BooleanArray", this.asDynamic().slice())
}

/**
 * Returns new array which is a copy of the original array.
 * 
 * @sample samples.collections.Arrays.CopyOfOperations.copyOf
 */
public actual fun CharArray.copyOf(): CharArray {
    return withType("CharArray", this.asDynamic().slice())
}

/**
 * Returns new array which is a copy of the original array, resized to the given [newSize].
 * The copy is either truncated or padded at the end with zero values if necessary.
 * 
 * - If [newSize] is less than the size of the original array, the copy array is truncated to the [newSize].
 * - If [newSize] is greater than the size of the original array, the extra elements in the copy array are filled with zero values.
 * 
 * @sample samples.collections.Arrays.CopyOfOperations.resizedPrimitiveCopyOf
 */
public actual fun ByteArray.copyOf(newSize: Int): ByteArray {
    return fillFrom(this, ByteArray(newSize))
}

/**
 * Returns new array which is a copy of the original array, resized to the given [newSize].
 * The copy is either truncated or padded at the end with zero values if necessary.
 * 
 * - If [newSize] is less than the size of the original array, the copy array is truncated to the [newSize].
 * - If [newSize] is greater than the size of the original array, the extra elements in the copy array are filled with zero values.
 * 
 * @sample samples.collections.Arrays.CopyOfOperations.resizedPrimitiveCopyOf
 */
public actual fun ShortArray.copyOf(newSize: Int): ShortArray {
    return fillFrom(this, ShortArray(newSize))
}

/**
 * Returns new array which is a copy of the original array, resized to the given [newSize].
 * The copy is either truncated or padded at the end with zero values if necessary.
 * 
 * - If [newSize] is less than the size of the original array, the copy array is truncated to the [newSize].
 * - If [newSize] is greater than the size of the original array, the extra elements in the copy array are filled with zero values.
 * 
 * @sample samples.collections.Arrays.CopyOfOperations.resizedPrimitiveCopyOf
 */
public actual fun IntArray.copyOf(newSize: Int): IntArray {
    return fillFrom(this, IntArray(newSize))
}

/**
 * Returns new array which is a copy of the original array, resized to the given [newSize].
 * The copy is either truncated or padded at the end with zero values if necessary.
 * 
 * - If [newSize] is less than the size of the original array, the copy array is truncated to the [newSize].
 * - If [newSize] is greater than the size of the original array, the extra elements in the copy array are filled with zero values.
 * 
 * @sample samples.collections.Arrays.CopyOfOperations.resizedPrimitiveCopyOf
 */
public actual fun LongArray.copyOf(newSize: Int): LongArray {
    return withType("LongArray", arrayCopyResize(this, newSize, 0L))
}

/**
 * Returns new array which is a copy of the original array, resized to the given [newSize].
 * The copy is either truncated or padded at the end with zero values if necessary.
 * 
 * - If [newSize] is less than the size of the original array, the copy array is truncated to the [newSize].
 * - If [newSize] is greater than the size of the original array, the extra elements in the copy array are filled with zero values.
 * 
 * @sample samples.collections.Arrays.CopyOfOperations.resizedPrimitiveCopyOf
 */
public actual fun FloatArray.copyOf(newSize: Int): FloatArray {
    return fillFrom(this, FloatArray(newSize))
}

/**
 * Returns new array which is a copy of the original array, resized to the given [newSize].
 * The copy is either truncated or padded at the end with zero values if necessary.
 * 
 * - If [newSize] is less than the size of the original array, the copy array is truncated to the [newSize].
 * - If [newSize] is greater than the size of the original array, the extra elements in the copy array are filled with zero values.
 * 
 * @sample samples.collections.Arrays.CopyOfOperations.resizedPrimitiveCopyOf
 */
public actual fun DoubleArray.copyOf(newSize: Int): DoubleArray {
    return fillFrom(this, DoubleArray(newSize))
}

/**
 * Returns new array which is a copy of the original array, resized to the given [newSize].
 * The copy is either truncated or padded at the end with `false` values if necessary.
 * 
 * - If [newSize] is less than the size of the original array, the copy array is truncated to the [newSize].
 * - If [newSize] is greater than the size of the original array, the extra elements in the copy array are filled with `false` values.
 * 
 * @sample samples.collections.Arrays.CopyOfOperations.resizedPrimitiveCopyOf
 */
public actual fun BooleanArray.copyOf(newSize: Int): BooleanArray {
    return withType("BooleanArray", arrayCopyResize(this, newSize, false))
}

/**
 * Returns new array which is a copy of the original array, resized to the given [newSize].
 * The copy is either truncated or padded at the end with null char (`\u0000`) values if necessary.
 * 
 * - If [newSize] is less than the size of the original array, the copy array is truncated to the [newSize].
 * - If [newSize] is greater than the size of the original array, the extra elements in the copy array are filled with null char (`\u0000`) values.
 * 
 * @sample samples.collections.Arrays.CopyOfOperations.resizedPrimitiveCopyOf
 */
public actual fun CharArray.copyOf(newSize: Int): CharArray {
    return withType("CharArray", fillFrom(this, CharArray(newSize)))
}

/**
 * Returns new array which is a copy of the original array, resized to the given [newSize].
 * The copy is either truncated or padded at the end with `null` values if necessary.
 * 
 * - If [newSize] is less than the size of the original array, the copy array is truncated to the [newSize].
 * - If [newSize] is greater than the size of the original array, the extra elements in the copy array are filled with `null` values.
 * 
 * @sample samples.collections.Arrays.CopyOfOperations.resizingCopyOf
 */
@Suppress("ACTUAL_WITHOUT_EXPECT")
public actual fun <T> Array<out T>.copyOf(newSize: Int): Array<T?> {
    return arrayCopyResize(this, newSize, null)
}

/**
 * Returns new array which is a copy of range of original array.
 */
@Suppress("ACTUAL_WITHOUT_EXPECT", "NOTHING_TO_INLINE")
public actual inline fun <T> Array<out T>.copyOfRange(fromIndex: Int, toIndex: Int): Array<T> {
    return this.asDynamic().slice(fromIndex, toIndex)
}

/**
 * Returns new array which is a copy of range of original array.
 */
@Suppress("NOTHING_TO_INLINE")
public actual inline fun ByteArray.copyOfRange(fromIndex: Int, toIndex: Int): ByteArray {
    return this.asDynamic().slice(fromIndex, toIndex)
}

/**
 * Returns new array which is a copy of range of original array.
 */
@Suppress("NOTHING_TO_INLINE")
public actual inline fun ShortArray.copyOfRange(fromIndex: Int, toIndex: Int): ShortArray {
    return this.asDynamic().slice(fromIndex, toIndex)
}

/**
 * Returns new array which is a copy of range of original array.
 */
@Suppress("NOTHING_TO_INLINE")
public actual inline fun IntArray.copyOfRange(fromIndex: Int, toIndex: Int): IntArray {
    return this.asDynamic().slice(fromIndex, toIndex)
}

/**
 * Returns new array which is a copy of range of original array.
 */
public actual fun LongArray.copyOfRange(fromIndex: Int, toIndex: Int): LongArray {
    return withType("LongArray", this.asDynamic().slice(fromIndex, toIndex))
}

/**
 * Returns new array which is a copy of range of original array.
 */
@Suppress("NOTHING_TO_INLINE")
public actual inline fun FloatArray.copyOfRange(fromIndex: Int, toIndex: Int): FloatArray {
    return this.asDynamic().slice(fromIndex, toIndex)
}

/**
 * Returns new array which is a copy of range of original array.
 */
@Suppress("NOTHING_TO_INLINE")
public actual inline fun DoubleArray.copyOfRange(fromIndex: Int, toIndex: Int): DoubleArray {
    return this.asDynamic().slice(fromIndex, toIndex)
}

/**
 * Returns new array which is a copy of range of original array.
 */
public actual fun BooleanArray.copyOfRange(fromIndex: Int, toIndex: Int): BooleanArray {
    return withType("BooleanArray", this.asDynamic().slice(fromIndex, toIndex))
}

/**
 * Returns new array which is a copy of range of original array.
 */
public actual fun CharArray.copyOfRange(fromIndex: Int, toIndex: Int): CharArray {
    return withType("CharArray", this.asDynamic().slice(fromIndex, toIndex))
}

/**
 * Returns an array containing all elements of the original array and then the given [element].
 */
@Suppress("ACTUAL_WITHOUT_EXPECT", "NOTHING_TO_INLINE")
public actual inline operator fun <T> Array<out T>.plus(element: T): Array<T> {
    return this.asDynamic().concat(arrayOf(element))
}

/**
 * Returns an array containing all elements of the original array and then the given [element].
 */
@Suppress("NOTHING_TO_INLINE")
public actual inline operator fun ByteArray.plus(element: Byte): ByteArray {
    return plus(byteArrayOf(element))
}

/**
 * Returns an array containing all elements of the original array and then the given [element].
 */
@Suppress("NOTHING_TO_INLINE")
public actual inline operator fun ShortArray.plus(element: Short): ShortArray {
    return plus(shortArrayOf(element))
}

/**
 * Returns an array containing all elements of the original array and then the given [element].
 */
@Suppress("NOTHING_TO_INLINE")
public actual inline operator fun IntArray.plus(element: Int): IntArray {
    return plus(intArrayOf(element))
}

/**
 * Returns an array containing all elements of the original array and then the given [element].
 */
@Suppress("NOTHING_TO_INLINE")
public actual inline operator fun LongArray.plus(element: Long): LongArray {
    return plus(longArrayOf(element))
}

/**
 * Returns an array containing all elements of the original array and then the given [element].
 */
@Suppress("NOTHING_TO_INLINE")
public actual inline operator fun FloatArray.plus(element: Float): FloatArray {
    return plus(floatArrayOf(element))
}

/**
 * Returns an array containing all elements of the original array and then the given [element].
 */
@Suppress("NOTHING_TO_INLINE")
public actual inline operator fun DoubleArray.plus(element: Double): DoubleArray {
    return plus(doubleArrayOf(element))
}

/**
 * Returns an array containing all elements of the original array and then the given [element].
 */
@Suppress("NOTHING_TO_INLINE")
public actual inline operator fun BooleanArray.plus(element: Boolean): BooleanArray {
    return plus(booleanArrayOf(element))
}

/**
 * Returns an array containing all elements of the original array and then the given [element].
 */
@Suppress("NOTHING_TO_INLINE")
public actual inline operator fun CharArray.plus(element: Char): CharArray {
    return plus(charArrayOf(element))
}

/**
 * Returns an array containing all elements of the original array and then all elements of the given [elements] collection.
 */
@Suppress("ACTUAL_WITHOUT_EXPECT")
public actual operator fun <T> Array<out T>.plus(elements: Collection<T>): Array<T> {
    return arrayPlusCollection(this, elements)
}

/**
 * Returns an array containing all elements of the original array and then all elements of the given [elements] collection.
 */
public actual operator fun ByteArray.plus(elements: Collection<Byte>): ByteArray {
    return fillFromCollection(this.copyOf(size + elements.size), this.size, elements)
}

/**
 * Returns an array containing all elements of the original array and then all elements of the given [elements] collection.
 */
public actual operator fun ShortArray.plus(elements: Collection<Short>): ShortArray {
    return fillFromCollection(this.copyOf(size + elements.size), this.size, elements)
}

/**
 * Returns an array containing all elements of the original array and then all elements of the given [elements] collection.
 */
public actual operator fun IntArray.plus(elements: Collection<Int>): IntArray {
    return fillFromCollection(this.copyOf(size + elements.size), this.size, elements)
}

/**
 * Returns an array containing all elements of the original array and then all elements of the given [elements] collection.
 */
public actual operator fun LongArray.plus(elements: Collection<Long>): LongArray {
    return arrayPlusCollection(this, elements)
}

/**
 * Returns an array containing all elements of the original array and then all elements of the given [elements] collection.
 */
public actual operator fun FloatArray.plus(elements: Collection<Float>): FloatArray {
    return fillFromCollection(this.copyOf(size + elements.size), this.size, elements)
}

/**
 * Returns an array containing all elements of the original array and then all elements of the given [elements] collection.
 */
public actual operator fun DoubleArray.plus(elements: Collection<Double>): DoubleArray {
    return fillFromCollection(this.copyOf(size + elements.size), this.size, elements)
}

/**
 * Returns an array containing all elements of the original array and then all elements of the given [elements] collection.
 */
public actual operator fun BooleanArray.plus(elements: Collection<Boolean>): BooleanArray {
    return arrayPlusCollection(this, elements)
}

/**
 * Returns an array containing all elements of the original array and then all elements of the given [elements] collection.
 */
public actual operator fun CharArray.plus(elements: Collection<Char>): CharArray {
    return fillFromCollection(this.copyOf(size + elements.size), this.size, elements)
}

/**
 * Returns an array containing all elements of the original array and then all elements of the given [elements] array.
 */
@Suppress("ACTUAL_WITHOUT_EXPECT", "NOTHING_TO_INLINE")
public actual inline operator fun <T> Array<out T>.plus(elements: Array<out T>): Array<T> {
    return this.asDynamic().concat(elements)
}

/**
 * Returns an array containing all elements of the original array and then all elements of the given [elements] array.
 */
@Suppress("NOTHING_TO_INLINE")
public actual inline operator fun ByteArray.plus(elements: ByteArray): ByteArray {
    return primitiveArrayConcat(this, elements)
}

/**
 * Returns an array containing all elements of the original array and then all elements of the given [elements] array.
 */
@Suppress("NOTHING_TO_INLINE")
public actual inline operator fun ShortArray.plus(elements: ShortArray): ShortArray {
    return primitiveArrayConcat(this, elements)
}

/**
 * Returns an array containing all elements of the original array and then all elements of the given [elements] array.
 */
@Suppress("NOTHING_TO_INLINE")
public actual inline operator fun IntArray.plus(elements: IntArray): IntArray {
    return primitiveArrayConcat(this, elements)
}

/**
 * Returns an array containing all elements of the original array and then all elements of the given [elements] array.
 */
@Suppress("NOTHING_TO_INLINE")
public actual inline operator fun LongArray.plus(elements: LongArray): LongArray {
    return primitiveArrayConcat(this, elements)
}

/**
 * Returns an array containing all elements of the original array and then all elements of the given [elements] array.
 */
@Suppress("NOTHING_TO_INLINE")
public actual inline operator fun FloatArray.plus(elements: FloatArray): FloatArray {
    return primitiveArrayConcat(this, elements)
}

/**
 * Returns an array containing all elements of the original array and then all elements of the given [elements] array.
 */
@Suppress("NOTHING_TO_INLINE")
public actual inline operator fun DoubleArray.plus(elements: DoubleArray): DoubleArray {
    return primitiveArrayConcat(this, elements)
}

/**
 * Returns an array containing all elements of the original array and then all elements of the given [elements] array.
 */
@Suppress("NOTHING_TO_INLINE")
public actual inline operator fun BooleanArray.plus(elements: BooleanArray): BooleanArray {
    return primitiveArrayConcat(this, elements)
}

/**
 * Returns an array containing all elements of the original array and then all elements of the given [elements] array.
 */
@Suppress("NOTHING_TO_INLINE")
public actual inline operator fun CharArray.plus(elements: CharArray): CharArray {
    return primitiveArrayConcat(this, elements)
}

/**
 * Returns an array containing all elements of the original array and then the given [element].
 */
@Suppress("ACTUAL_WITHOUT_EXPECT", "NOTHING_TO_INLINE")
public actual inline fun <T> Array<out T>.plusElement(element: T): Array<T> {
    return this.asDynamic().concat(arrayOf(element))
}

/**
 * Sorts the array in-place.
 */
@library("primitiveArraySort")
public actual fun IntArray.sort(): Unit {
    definedExternally
}

/**
 * Sorts the array in-place.
 */
public actual fun LongArray.sort(): Unit {
    if (size > 1)
        sort { a: Long, b: Long -> a.compareTo(b) }
}

/**
 * Sorts the array in-place.
 */
@library("primitiveArraySort")
public actual fun ByteArray.sort(): Unit {
    definedExternally
}

/**
 * Sorts the array in-place.
 */
@library("primitiveArraySort")
public actual fun ShortArray.sort(): Unit {
    definedExternally
}

/**
 * Sorts the array in-place.
 */
@library("primitiveArraySort")
public actual fun DoubleArray.sort(): Unit {
    definedExternally
}

/**
 * Sorts the array in-place.
 */
@library("primitiveArraySort")
public actual fun FloatArray.sort(): Unit {
    definedExternally
}

/**
 * Sorts the array in-place.
 */
@library("primitiveArraySort")
public actual fun CharArray.sort(): Unit {
    definedExternally
}

/**
 * Sorts the array in-place according to the natural order of its elements.
 */
public actual fun <T : Comparable<T>> Array<out T>.sort(): Unit {
    if (size > 1)
        sort { a: T, b: T -> a.compareTo(b) }
}

/**
 * Sorts the array in-place according to the order specified by the given [comparison] function.
 */
@kotlin.internal.InlineOnly
public inline fun <T> Array<out T>.sort(noinline comparison: (a: T, b: T) -> Int): Unit {
    asDynamic().sort(comparison)
}

/**
 * Sorts the array in-place according to the order specified by the given [comparison] function.
 */
@kotlin.internal.InlineOnly
public inline fun ByteArray.sort(noinline comparison: (a: Byte, b: Byte) -> Int): Unit {
    asDynamic().sort(comparison)
}

/**
 * Sorts the array in-place according to the order specified by the given [comparison] function.
 */
@kotlin.internal.InlineOnly
public inline fun ShortArray.sort(noinline comparison: (a: Short, b: Short) -> Int): Unit {
    asDynamic().sort(comparison)
}

/**
 * Sorts the array in-place according to the order specified by the given [comparison] function.
 */
@kotlin.internal.InlineOnly
public inline fun IntArray.sort(noinline comparison: (a: Int, b: Int) -> Int): Unit {
    asDynamic().sort(comparison)
}

/**
 * Sorts the array in-place according to the order specified by the given [comparison] function.
 */
@kotlin.internal.InlineOnly
public inline fun LongArray.sort(noinline comparison: (a: Long, b: Long) -> Int): Unit {
    asDynamic().sort(comparison)
}

/**
 * Sorts the array in-place according to the order specified by the given [comparison] function.
 */
@kotlin.internal.InlineOnly
public inline fun FloatArray.sort(noinline comparison: (a: Float, b: Float) -> Int): Unit {
    asDynamic().sort(comparison)
}

/**
 * Sorts the array in-place according to the order specified by the given [comparison] function.
 */
@kotlin.internal.InlineOnly
public inline fun DoubleArray.sort(noinline comparison: (a: Double, b: Double) -> Int): Unit {
    asDynamic().sort(comparison)
}

/**
 * Sorts the array in-place according to the order specified by the given [comparison] function.
 */
@kotlin.internal.InlineOnly
public inline fun CharArray.sort(noinline comparison: (a: Char, b: Char) -> Int): Unit {
    asDynamic().sort(comparison)
}

/**
 * Sorts the array in-place according to the order specified by the given [comparator].
 */
public actual fun <T> Array<out T>.sortWith(comparator: Comparator<in T>): Unit {
    if (size > 1)
        sort { a, b -> comparator.compare(a, b) }
}

/**
 * Returns a *typed* object array containing all of the elements of this primitive array.
 */
public actual fun ByteArray.toTypedArray(): Array<Byte> {
    return js("[]").slice.call(this)
}

/**
 * Returns a *typed* object array containing all of the elements of this primitive array.
 */
public actual fun ShortArray.toTypedArray(): Array<Short> {
    return js("[]").slice.call(this)
}

/**
 * Returns a *typed* object array containing all of the elements of this primitive array.
 */
public actual fun IntArray.toTypedArray(): Array<Int> {
    return js("[]").slice.call(this)
}

/**
 * Returns a *typed* object array containing all of the elements of this primitive array.
 */
public actual fun LongArray.toTypedArray(): Array<Long> {
    return copyOf().unsafeCast<Array<Long>>()
}

/**
 * Returns a *typed* object array containing all of the elements of this primitive array.
 */
public actual fun FloatArray.toTypedArray(): Array<Float> {
    return js("[]").slice.call(this)
}

/**
 * Returns a *typed* object array containing all of the elements of this primitive array.
 */
public actual fun DoubleArray.toTypedArray(): Array<Double> {
    return js("[]").slice.call(this)
}

/**
 * Returns a *typed* object array containing all of the elements of this primitive array.
 */
public actual fun BooleanArray.toTypedArray(): Array<Boolean> {
    return copyOf().unsafeCast<Array<Boolean>>()
}

/**
 * Returns a *typed* object array containing all of the elements of this primitive array.
 */
public actual fun CharArray.toTypedArray(): Array<Char> {
    return Array<Char>(size, { i -> this[i] })
}

